export const msalConfig = {
    auth: {
        clientId: "", 
        authority: "",
        redirectUri: window.location.search, 
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: [
        "openid",
        "profile",
        "email",
        "api://36b5e0bc-bf45-48df-912c-7244d4123b62/access_as_user",
    ], 
};
